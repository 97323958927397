import React, { forwardRef } from 'react'
import { track } from 'analytics'

import { type Label } from 'typings/graphql'

import { HeadlineBlock } from 'components/dataDisplay'
import { Button } from 'components/inputs'
import { WidthContainer } from 'components/layout'

import type { AddToButtonPropsWithoutProduct } from 'compositions/buttons/AddToButton/AddToButton'

import ProductCarousel from '../ProductCarousel/ProductCarousel'


export type ProductSmallCarouselSectionProps = {
  className?: string
  buttonProps?: AddToButtonPropsWithoutProduct
  title: Intl.Message | string
  supTitle?: Intl.Message | string
  text?: Intl.Message | string
  viewAllTitle?: Intl.Message | string
  viewAllLink?: string
  viewAllStyle?: 'primary' | 'secondary'
  products: ProductCard.SmallCardProps['data'][]
  excludeLabels?: Label[]
  metadata: QueueModule.AddItemInput['metadata']
  isFetching?: boolean
  lazy?: boolean
  ignoreWidthContainer?: boolean
  withoutLink?: boolean
  dataAttributes?: Record<string, any>
  recommenderStrategies?: Record<string, string>
  onProductLinkClick?: ProductCard.SmallCardProps['onProductLinkClick']
  'data-testid': string
  removeZeroCents?: boolean
}

const ProductSmallCarouselSection = forwardRef<HTMLDivElement, ProductSmallCarouselSectionProps>((props, ref) => {
  const {
    className, buttonProps, title, supTitle, viewAllLink, viewAllTitle, viewAllStyle = 'secondary',
    products, metadata, excludeLabels, isFetching, ignoreWidthContainer, withoutLink, 'data-testid': dataTestId, lazy,
    dataAttributes, recommenderStrategies, onProductLinkClick, text, removeZeroCents,
  } = props

  if (!isFetching && !products?.length) {
    return null
  }

  const extraProps = buttonProps ? { buttonProps } : undefined

  return (
    <WidthContainer ref={ref} className={className} data-testid={dataTestId} ignore={ignoreWidthContainer}>
      <HeadlineBlock
        supTitle={supTitle}
        title={title}
        text={text}
      />
      <ProductCarousel
        products={products}
        excludeLabels={excludeLabels}
        metadata={metadata}
        isFetching={isFetching}
        lazy={lazy}
        withoutLink={withoutLink}
        dataAttributes={dataAttributes}
        recommenderStrategies={recommenderStrategies}
        onProductLinkClick={onProductLinkClick}
        removeZeroCents={removeZeroCents}
        {...extraProps}
      />
      {
        Boolean(viewAllTitle && viewAllLink) && (
          <div className="mt-32 text-center">
            <Button
              to={viewAllLink}
              title={viewAllTitle}
              size={56}
              width={320}
              style={viewAllStyle}
              fullWidthOnMobile
              onClick={() => {
                track('Show more products click', {
                  placement: metadata.placement,
                })
              }}
              data-testid="viewAll"
            />
          </div>)
      }
    </WidthContainer>
  )
})


export default React.memo(ProductSmallCarouselSection)
